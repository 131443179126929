import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "routing"
    }}>{`Routing`}</h1>
    <p>{`Routing in rework.js is handled by `}<a parentName="p" {...{
        "href": "https://reacttraining.com/react-router/web"
      }}>{`React Router DOM`}</a>{`, with sprinkles added on top.`}</p>
    <h2 {...{
      "id": "creating-a-new-route"
    }}>{`Creating a new Route`}</h2>
    <p>{`The default router treats all files named `}<inlineCode parentName="p">{`*.route.js`}</inlineCode>{`* as route definitions.`}</p>
    <p>{`The route file should default-export an object containing the metadata of the route: `}<inlineCode parentName="p">{`path`}</inlineCode>{` & `}<inlineCode parentName="p">{`component`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/pages/home.route.ts

export default {
  path: '/',

  // the react component to render on the homepage, works like any other component.
  // see bellow for lazy-loading
  component: MyLazyLoadedComponent,
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`The actual default pattern is `}<inlineCode parentName="li">{`src/**/*.route.{js,jsx,mjs,ts,tsx}`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "code-splitting--lazy-loading"
    }}>{`Code Splitting & Lazy Loading`}</h2>
    <p>{`Code splitting in rework.js is handled by `}<a parentName="p" {...{
        "href": "https://www.smooth-code.com/open-source/loadable-components/"
      }}>{`Loadable Components`}</a>{`.`}</p>
    <p>{`If we take the example above and expand it to lazy-load the homepage, we would end up with the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/pages/home/home.route.ts

import * as React from 'react';
import loadable from '@loadable/component';
import CircularProgress from '@material-ui/core/CircularProgress';

export default {
  path: '/',

  // lazy-load the homepage
  component: loadable(() => import('./home.view'), {
    fallback: <CircularProgress />,
  }),
}
`}</code></pre>
    <p>{`N.B.: You can lazy-load components anywhere using loadable, this is `}<em parentName="p">{`not`}</em>{` strictly limited to route definitions.`}</p>
    <p>{`That library is fully integrated with the framework, including server-side rendering.
Please refer to `}<a parentName="p" {...{
        "href": "https://www.smooth-code.com/open-source/loadable-components/"
      }}>{`their documentation`}</a>{` for more information on code splitting.`}</p>
    <h2 {...{
      "id": "catch-all-routes-404"
    }}>{`Catch-all routes (404)`}</h2>
    <p>{`Creating a catch-all route works pretty much the same. It is your standard route definition with a few differences:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`path`}</inlineCode>{` must be set to `}<inlineCode parentName="li">{`*`}</inlineCode>{` to match all urls`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`priority`}</inlineCode>{` must be set to a low number so the route is matched last (if a catch-all route is matched first, all pages will display the catch-all).`}</li>
      <li parentName="ul">{`(SSR) `}<inlineCode parentName="li">{`status`}</inlineCode>{` can be set to any HTTP status code (eg. `}<inlineCode parentName="li">{`404`}</inlineCode>{`) if you wish to change the status code the server will return.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// src/pages/404/404.route.ts

import * as React from 'react';
import loadable from '@loadable/component';
import CircularProgress from '@material-ui/core/CircularProgress';

export default {
  // match all urls
  path: '*',
  // make this route definition match last
  priority: 0,
  // if this route matches, change ssr http status to 404
  status: 404,

  component: loadable(() => import('./404.view'), {
    fallback: <CircularProgress />,
  }),
};
`}</code></pre>
    <h2 {...{
      "id": "http-status-codes"
    }}>{`HTTP status codes`}</h2>
    <p>{`Setting the HTTP status for SSR can be done either by setting the `}<inlineCode parentName="p">{`status`}</inlineCode>{` property on your route definition
or using the `}<a parentName="p" {...{
        "href": "https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/server-rendering.md#404-401-or-any-other-status"
      }}>{`React-Router APIs`}</a></p>
    <p>{`The react-router way is a bit cumbersome so rework.js exposes two utilities you can use instead: `}<inlineCode parentName="p">{`HttpStatus`}</inlineCode>{` & `}<inlineCode parentName="p">{`useHttpStatus`}</inlineCode>{`. They are used like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript",
        "metastring": "jsx",
        "jsx": true
      }}>{`// HttpStatus component

// src/pages/404/404.view.tsx
import * as React from 'react';
import { HttpStatus } from '@reworkjs/core/router';

function My404Page() {
  return (
    <>
      <HttpStatus code={404} />
      Resource not found!
    </>
  );
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-typescript",
        "metastring": "jsx",
        "jsx": true
      }}>{`// useHttpStatus hook

// src/pages/404/404.view.tsx
import * as React from 'react';
import { useHttpStatus } from '@reworkjs/core/router';

function My404Page() {

  useHttpStatus(404);

  return (
    <>
      Resource not found!
    </>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "advanced-routing"
    }}>{`Advanced Routing`}</h2>
    <p>{`While this route-loading system, it also limits what can be done with React-Router.`}</p>
    <p>{`If you wish to bypass it and come back to React-Router, you can create a single route file that will act as your router:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript",
        "metastring": "jsx",
        "jsx": true
      }}>{`// src/pages/router.route.tsx

import * as React from 'react';
import { Switch } from 'react-router-dom';

export default {
  // match all urls
  path: '*',
  component: MyRouter,
};

function MyRouter() {
  return (
    <Switch>
      {/* check out react-router for documentation on their routing! */}
    </Switch>
  );
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      